// Imports

import Vue from 'vue'
import App from '@/App.vue'
import authHeader from "@/services/auth";
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueOnToast from 'vue-on-toast'
import isMobile from 'mobile-device-detect'
import isTablet from 'mobile-device-detect'
import router from '@/router'
import store from '@/store'
import Highcharts from "highcharts";
import stockInit from "highcharts/modules/stock";
import mapInit from "highcharts/modules/map";
import Treemap from 'highcharts/modules/treemap'
import HighchartsVue from "highcharts-vue";
import VueMeta from "vue-meta";

stockInit(Highcharts);
mapInit(Highcharts);
Treemap(Highcharts);

Vue.use(HighchartsVue);
Vue.use(VueAxios, axios)
Vue.use(VueOnToast)
Vue.use(isMobile)
Vue.use(isTablet)
Vue.use(VueMeta)

// Main code; function calls

/**
 * Code executed on each vue-router redirection before the redirection takes effect.
 * The redirection will take effect on "next()" call
 */
router.beforeEach((to, from, next) => {
  const router_comes_from_same_site = from.path != null;
  const router_is_reloading_same_page = from.path == "/";
  const router_is_pointing_to_login = to.name == "Login";
  
  if(router_is_pointing_to_login) {
      if(router_comes_from_same_site && !router_is_pointing_to_login) {
          storePreviousLocation(from.name);
      }
      next();
  }
  else {
      window.sessionStorage.removeItem('ticker_search_by_user');
      if(!router_is_reloading_same_page) {
          localStorage.setItem('page_needs_reloading', false); //TODO boost chetao de la speed
      }
      next();
      //TODO a eliminar si no dependemos de oauth
      //handleLoginStatus(to.name, next);
  }
})

/**
 * Code executed on each vue-router redirection after the redirection takes effect.
 */
router.afterEach((to, from) => {
  refreshPageIfNeeded();
})

/**
 * Set up main vue app
 */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')







//vvv function declarations vvv

/*function handleLoginStatus(to_name, next) {
  const page_needs_reloading = localStorage.getItem('page_needs_reloading') == 'true';
  if(!page_needs_reloading) {
    const user_access_token = localStorage.getItem('user_access_token');
    const user_access_token_exists = user_access_token 
                                     && user_access_token != null
                                     && user_access_token != 'null' 
                                     && user_access_token.length != 0;
  
    const setUserInfoInState = async function () {
      try {
        const response = await userInfoPromise();
        const info = response.data;
        store.state.user_info = info;
        store.state.user_id = info.uid;
        if (info.uid == 0) {
          if (user_access_token_exists) {
            localStorage.setItem('user_location_before_login', to_name);
            router.push({ name: 'Login' });
          }
          else {
            store.state.user_id = 0;
          }
        }
        else {
          handleBackSession(info.uid);
        }
      }
      catch (error) {
        const error_has_data = error && error != null && error.response && error.response != null;
        const uid_request_unauthorized = error_has_data ? error.response.status == 401 || error.response.status == 403 : null;
  
        if (error_has_data && uid_request_unauthorized) {
          if (user_access_token_exists) {
            localStorage.setItem('user_location_before_login', to_name);
            router.push({ name: 'Login' });
          }
          else {
            store.state.user_id = 0;
            localStorage.removeItem('user_access_token');
          }
        }
      }
      next();
    }
  
    setUserInfoInState(); 
  }
  else {
    next();
  }
}*/

function storePreviousLocation(previous_location_name) {
  const previous_location_store = localStorage.getItem('user_location_before_login');
  const previous_location_exists_in_store = previous_location_store 
                                            && previous_location_store != null 
                                            && previous_location_store != 'null';
  if (!previous_location_exists_in_store) {
    localStorage.setItem('user_location_before_login', previous_location_name);
  }
}

/*function userUidPromise() {
  const baseURL = process.env.VUE_APP_API;
  const getUidURI = '/api/v1/users/user/uid';
  const requestURL = baseURL + getUidURI;
  return axios.get(requestURL, { headers: authHeader() });
}*/

/*function userInfoPromise() {
  const baseURL = process.env.VUE_APP_API;
  const getInfoURI = '/api/v1/users/user/full-info';
  const requestURL = baseURL + getInfoURI;
  return axios.get(requestURL, { headers: authHeader() });
}*/

function refreshPageIfNeeded() {
  const page_needs_reloading = localStorage.getItem('page_needs_reloading') == 'true';
  
  if (page_needs_reloading) {
    localStorage.setItem('page_needs_reloading', false);
    location.reload();
  }
}

/*function handleBackSession(uid) {
  const baseURL = process.env.VUE_APP_API;
  const getSessionURI = '/api/v1/users/user/user_logged/' + uid;
  const requestURL = baseURL + getSessionURI;
  const destroyFrontToken = function() {
    localStorage.removeItem('user_access_token');
    location.reload();
  }

  axios.get(requestURL, { headers: authHeader() })
  .then(
    reponse => {
      const uid = reponse.data.uid;
      const existsValidSession = uid && uid != null && uid != 'null' && uid != 0;
      if(!existsValidSession) {
        destroyFrontToken();
      }
    }
  ).catch(
    error => {
      console.error(error);

      const unauthroized_error = error 
                                 && error.response
                                 && error.response.status
                                 && (error.response.status == 403 || error.response.status == 401);
      
      if(unauthroized_error) {
        destroyFrontToken();
      }
    }
  );
}*/

//automatic detect environment function to links (redirect to VUE)
Vue.prototype.$detectEnvironment = function() {
  const environment = process.env.NODE_ENV;
  if (environment === "development") {
    return "http://192.168.208.2";
  } else {
    return process.env.VUE_APP_API;
  }
}