/*
Component: Menu
Purpose: 
It is the main menu and contains the redirects to all pages.
Main functionality:
  -Redirect to any page in the web.
Secondary functionalities:
  -Login and logout
  -Switch theme
  
Useful notes:
Css classes for managing opening a closing of dropdown menus work in the exact opposite way they should.
I have absolutely no clue about why this happens but since it works by reversing the booleans i wont be expending more time there.
  
Decission documentation:

*/

<template>
  <div
  @mouseover="setExpandMenuClass(Date.now())"
  @mouseleave="setContractMenuClass(Date.now())"
  id="menu-container" :class="this.hover_class"
  >
    <!-- Envelopes nav links with fixed width so changes in root width dont destroy layout -->
    <div id="menu-container-frame">

      <!-- Profile/Login container -->
      <div id="profile-container">

        <!-- Profile section -->
        <div v-if="_g_IsUserLogged">

          <div class="profile-selector-container" :class="getCorrectDropdownCss(!this.user_selector.dropdown_active, this.user_selector.options)" @click="()=>this.user_selector.dropdown_active = !this.user_selector.dropdown_active">
            <div class="profile-pic-container">
              <span id="profile-pic-center-helper"></span>
              <span class="profile-pic nav-link-icon material-icons-outlined md-24">account_circle</span>
            </div>
            <div class="profile-text-container">
                <span class="profile-title-text typo-headline-6">Mi cuenta</span>
                <span class="profile-title-text typo-body-2">{{ username }}</span>
            </div>
            <div class="nav-link-dropdown-icon-container">
              <span class="nav-link-icon material-icons-outlined md-24">arrow_drop_down</span>
            </div>
          </div>

          <!-- Profile options -->
          <div class="profile-options-container" :class="this.user_selector.dropdown_active ? 'dropdown-options-closed' : ''">
            <div class="profile-options-container-frame">

              <router-link tag="div" :to="{ name: option.url_name }" v-for="(option, subindex) in this.user_selector.options" class="nav-dropdown-option" :class="getOptionSelectedClassIfSelected(option.url_name)" :key="subindex">
                <div class="nav-link-icon-container">
                  <span class="nav-link-text">{{option.acronym}}</span>
                </div>
                <div class="nav-link-text-container">
                  <span class="nav-link-text typo-caption">{{option.name}}</span>
                </div>
              </router-link>

              <div id="log-out-button-container" @click="this.logOut">
                <div class="nav-link-icon-container">
                    <span class="nav-link-text log-out-text">CS</span>
                </div>
                <div class="nav-link-text-container">
                  <span class="nav-link-text log-out-text typo-caption">Cerrar sesión</span>
                </div>
              </div>

            </div>
          </div>

        </div>

        <!-- Login section -->
        <router-link v-else-if="(is_basico_off == false && is_avanzado_off == false) || (is_basico_off == undefined && is_avanzado_off == undefined)" id="login-selector" class="nav-selector-container" tag="div" :to="{ name: 'Login' }">
          <div class="nav-link-icon-container">
            <span class="nav-link-icon material-icons-outlined md-24">login</span>
          </div>
          <div class="nav-link-text-container">
            <span class="nav-link-text">Iniciar sesión</span>
          </div>
        </router-link>

        <div v-else>
          <div id="log-out-button-container" @click="this.logOut">
            <div class="nav-link-icon-container">
                <span class="nav-link-text log-out-text">CS</span>
            </div>
            <div class="nav-link-text-container">
              <span class="nav-link-text log-out-text typo-caption">Cerrar sesión</span>
            </div>
          </div>
          <a id="courses-button-container" href="/app/courses">
            <div class="nav-link-icon-container">
              <span class="nav-link-icon material-icons-outlined md-24">school</span>
            </div>
            <div class="nav-link-text-container">
              <span class="nav-link-text typo-caption">Próximos cursos</span>
            </div>
          </a>
        </div>
        

      </div>

      <!-- Nav links container-->
      <div id="link-container">

        <!-- Selectors section -->
        <template v-for="(selector, index) in main_selectors">

          <!-- Special selectors section -->
          <template v-if="typeof selector.options[0] == 'string'">
            <a v-if="is_admin && selector.options[0] == 'admin'" href="/admin/structure/menu/manage/main-menu" target="_blank" :key="index">
              <div class="nav-selector-container">
                <div class="nav-link-icon-container">
                  <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
                </div>
                <div class="nav-link-text-container">
                  <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
                </div>
              </div>
            </a>
          </template>

          <!-- Standalone Alta Eurekers -->
          <template v-if="typeof selector.options[0] == 'string'">
            <a v-if="is_basico_asiste_avanzado && selector.options[0] == 'basico_asiste_avanzado'" href="/registro/eureker" target="_blank" :key="index">
              <div class="nav-selector-container important-highlight">
                <div class="nav-link-icon-container">
                  <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
                </div>
                <div class="nav-link-text-container">
                  <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
                </div>
              </div>
            </a>
          </template>
          
          <!-- Standalone RE-Alta Eurekers -->
          <template v-if="typeof selector.options[0] == 'string'">
            <a v-if="is_avanzado_off && selector.options[0] == 'avanzado_off'" href="/area_avanzado_off" target="_blank" :key="index">
              <div class="nav-selector-container important-highlight">
                <div class="nav-link-icon-container">
                  <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
                </div>
                <div class="nav-link-text-container">
                  <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
                </div>
              </div>
            </a>
          </template>

          <!-- Standalone Servicios Eurekers for basico_offs -->
          <template v-if="typeof selector.options[0] == 'string'">
            <a v-if="is_basico_off && selector.options[0] == 'basico_off'" href="/curso-de-bolsa" target="_blank" :key="index">
              <div class="nav-selector-container important-highlight">
                <div class="nav-link-icon-container">
                  <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
                </div>
                <div class="nav-link-text-container">
                  <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
                </div>
              </div>
            </a>
          </template>

          <!-- Standalone selectors section -->
          <router-link v-else-if="isEmpty(selector.options)" tag="div" :to="{ name: selector.url_name }" class="nav-selector-container" :class="getSelectedClassIfSelected(selector.url_name)" :key="index">
            <div class="nav-link-icon-container">
              <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
            </div>
            <div v-if="selector.name == 'Foro'" class="nav-link-text-container">
              <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
            </div>
            <div v-else class="nav-link-text-container">
              <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
            </div>
          </router-link>

          <!-- Dropdown selectors section -->
          <div v-else :key="index">

            <!-- Dropdown main selector -->
            <div class="nav-selector-container" :class="getCorrectDropdownCss(!selector.dropdown_active, selector.options)" @click="()=>selector.dropdown_active = !selector.dropdown_active">
              <div class="nav-link-icon-container">
                <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
              </div>
              <div class="nav-link-text-container">
                <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
              </div>
              <div class="nav-link-dropdown-icon-container">
                <span class="nav-link-icon material-icons-outlined md-24">arrow_drop_down</span>
              </div>
            </div>

            <!-- Dropdown options -->
            <div class="nav-dropdown-options-container" :class="selector.dropdown_active ? 'dropdown-options-closed' : ''">
              <div class="nav-dropdown-options-container-frame">

                <router-link tag="div" :to="{ name: option.url_name }" v-for="(option, subindex) in selector.options" class="nav-dropdown-option" :class="getOptionSelectedClassIfSelected(option.url_name)" :key="subindex">
                  <div class="nav-link-icon-container">
                    <span class="nav-link-text">{{option.acronym}}</span>
                  </div>
                  <div class="nav-link-text-container">
                    <span class="nav-link-text typo-caption">{{option.name}}</span>
                  </div>
                </router-link>

              </div>
            </div>

          </div>
        </template>
        
      </div>

      <!-- Bottom pinned nav links container -->
      <div id="bottom-pinned-container">

        <!-- Notifications -->
        <div id="notification-selector" class="nav-selector-container" @click="_handlerViewNotificationsContainer">
          <div class="nav-link-icon-container">
            <div style="display: flex; flex-direction: row; margin-left: 22px;">
              <span class="nav-link-icon material-icons-outlined md-24">notifications</span>
              <div class="circle" v-if="contador_notificaciones != 0">
              </div>
            </div>
          </div>
          <div class="nav-link-text-container">
            <span class="nav-link-text">Notificaciones</span>
          </div>
        </div>

        <!-- Theme switcher -->
        <div id="theme-selector" class="nav-selector-container" @click="changeInterfacePlatinum">
          <div class="nav-link-icon-container">
            <span class="nav-link-icon material-icons-outlined md-24">contrast</span>
          </div>
          <div class="nav-link-text-container">
            <span class="nav-link-text">Cambiar tema de color</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from "@/services/auth";

import mainMenu from "@/components/menu/configs/main_menu.json";
import userMenu from "@/components/menu/configs/user_menu.json";
import APICaller from "@/components/codeComponents/APICaller.js";

import { mapGetters, mapMutations } from "vuex";

export default {
  mixins: [APICaller],
  data() {
    return {
      main_selectors: mainMenu,
      user_selector: userMenu,
      current_route: "",
      logged_in: false,

      last_menu_hover: -1,
      menu_hover_open_timeout_ms: 250,
      menu_hover_close_timeout_ms: 250,
      hover_class: "menu-closed",
      username: '',
      is_admin: false,
      uid: 0,
      is_basic_user: "",
      is_basico_asiste_avanzado: false,
      router_in_stockchart: false,
      notifications_opened: false,
      contador_notificaciones: 0,
      is_avanzado_off: false,
      is_basico_off: false,
    };
  },
  watch: {
    '$route.name': {
      handler: function() {
        this.current_route = this.$route.name;
      }
    }
  },
  created(){
    this._getUsername();
    this.current_route = this.$route.name;
  },
  mounted() {
    var waiting_for_user = setInterval(()=>{
      if(this._g_User.uid != undefined){
        this.initUserInfo();
        this._getAllUserNotifications();
        clearInterval(waiting_for_user);
      }
    },200)
    const current_uri = location.pathname;
    const uri_elems = current_uri.split("/");
    if(uri_elems.length > 2) {
      if(uri_elems[2] === 'graficador-dinamico') {
        this.router_in_stockchart = true;
      } 
    }
  },
  computed: {
    ...mapGetters(['_g_ThemeName']),
    ...mapGetters(['_g_IsUserLogged', '_g_UserId', "_g_User", "_g_UserFeatures"]),
  },
  methods: {
    //Ini methods

    initializeZopim() {
      if(this._contieneChat(this._g_User.roles)){
        var self = this;
        window.$zopim||(function(d,s){
          var $zopim;
          var waitLoop = setInterval(function(){
            if ($zopim === undefined) {
              return;
            }
            clearInterval(waitLoop);
          }, 100);
          var path= window.location;
          if(path.search != "?=iframe"){
            var z=$zopim=function(c){z._.push(c)},$=z.s=
            d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
            _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
          }
          $.src="https://v2.zopim.com/?3uyzu9WJ3yai34gTpJf8baPKo29UBiMS";
          if (self.uid != 0) {
            if(self.is_basic_user){
              $.src="https://v2.zopim.com/?4DFMToy5u4DzMDnKWPxLFQMMZGE9kMO8";
            }else if(self.is_basico_off || self.is_avanzado_off){
              $.src="https://v2.zopim.com/?3uyzu9WJ3yai34gTpJf8baPKo29UBiMS";
            }else if(self._g_User.is_only_authenticated_user){
              $.src="https://v2.zopim.com/?3uyzu9WJ3yai34gTpJf8baPKo29UBiMS";
            }else{
              $.src="https://v2.zopim.com/?3xJ0MwHfqyS5wU5mFlrhuw9icA84S5u4";
            }
          }
  
          z.t=+new Date;
          $.type="text/javascript";
          e.parentNode.insertBefore($,e)
          })(document,"script");
      }
    },

    _contieneChat: function(obj) {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          if (typeof obj[key] === 'string' && obj[key].includes('Chat')) {
            return true;
          }
        }
      }
      return false;
    },

    initUserInfo: function(){
      this.is_basico_off = this._g_User.is_basico_off;
      this.is_avanzado_off = this._g_User.is_avanzado_off;
      this.is_basico_asiste_avanzado = this._g_User.is_basico_asiste_avanzado;
      this.uid = this._g_User.uid;
      this.is_admin = this._g_User.is_admin_user;
      this.is_basic_user = this._g_User.is_basic_user;
      this.initializeZopim();
    },

    //Open-close functionality

    toggleHoverClass(hover_time, hover_class) {
      this.last_menu_hover = hover_time;
      let checkChangesFunction = function() {
        if(hover_time == this.last_menu_hover) {
          this.hover_class = hover_class;
        }
      }
      return checkChangesFunction.bind(this);
    },

    setExpandMenuClass(hover_time) {
      setTimeout(this.toggleHoverClass(hover_time, "menu-open"), this.menu_hover_open_timeout_ms);
    },
    setContractMenuClass(hover_time) {
      setTimeout(this.toggleHoverClass(hover_time, "menu-closed"), this.menu_hover_close_timeout_ms);
    },

    //Theming

    switchTheme() {
      if(localStorage.getItem('dark_theme') != undefined) {
        localStorage.setItem('dark_theme', !this._g_ThemeName);
        this.changeInterfacePlatinum()
      }
    },

    changeInterfacePlatinum() {

      let success = () => {
        location.reload();
      };

      let url = '/usuarios/change-gd-interface-color';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('002', 'Error cambiando el tema de color.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    //Log out method

    logOut() {

      let success = () => {
        localStorage.removeItem('user_access_token');
        location.reload();
      };
      let url = '/api/v1/users/user/log-out';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('003', 'Error cerrando la sesión.');

      this._getAPICall(url, successHandler, failureHandler);
    },


    //HTML vue methods

    //Return selected class if selector equals current route
    getSelectedClassIfSelected(selector_url) {
      return this.current_route == selector_url ? 'selected' : '';
    },
    
    //Returns selected class if parameter matches with current router url
    getOptionSelectedClassIfSelected(selector_url) {
      return this.current_route == selector_url ? 'option-selected' : '';
    },

    //Returns whether an array is empty
    isEmpty(list) {
      return list.length === 0;
    },

    //Returns true if any option in the array parameter (options) has a url matching with current router url
    isChildSelected(options) {
      let res = false;
      for (let i = 0; i < options.length; i++) {
        res = options[i].url_name == this.current_route;
        if(res) {break;}
      }
      return res;
    },

    //Returns needed dropdown styles
    getCorrectDropdownCss(dropdown_active, options) {
      let final_style = "";
      let isChildSelected = this.isChildSelected(options);
      if(dropdown_active && isChildSelected) {
        final_style = 'selected-dropdown child-selected';
      }
      else if(dropdown_active) {
        final_style = 'selected-dropdown';
      }
      else if(isChildSelected) {
        final_style = 'child-selected';
      }

      return final_style;
    },

    _getUsername(){
      axios.get(process.env.VUE_APP_API + '/api/v1/users/user/name', { headers: authHeader() })
      .then(
        response => {
          this.username = response.data.name
        },
        error => {
        }
      )
    },
    _handlerViewNotificationsContainer: function(){
      setTimeout(this.toggleHoverClass(0, "menu-closed"), 0);
      if(this.notifications_opened){
        let success = response => {
          this.notifications_opened = false
          this.$emit("close_notification_container")
          this._getAllUserNotifications()
        }

        let url = "/api/v1/eurekers-notifications/read-notifications/" + this._g_UserId;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('316', 'Error leyendo las notificaciones.');

        this._getAPICall(url, successHandler, failureHandler);
      }else{
        this.notifications_opened = true
        this.$emit("open_notification_container")
      }
    },
    _getAllUserNotifications: function(){
      let success = response => {
        this.contador_notificaciones = 0
        let notifications = this._checkTimestamps(response.data);
        notifications.forEach(element=>{
          if(element.status == '0'){
            this.contador_notificaciones++
          }
        })
      }

      let url = "/api/v1/eurekers-notifications/" + this._g_UserId;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('317', 'Error recuperando las notificaciones.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _checkTimestamps: function(arr) {
      var notifications = [];
      var today = new Date().getTime(); // get current timestamp
      for(var i = 0; i < arr.length; i++) {
        if(arr[i].date*1000 <= today) {
          notifications.push(arr[i]);
        }
      }
      return notifications;
    }, 

    _reloadPage(){
      window.location.reload();
    }
  },
}
</script>

<style lang="scss">
//modifiable attributes
$selector_height: 48px;
$login_selector_focus_padding: 15px;
$login_selector_not_focus_padding: 7.5px;
$login_selector_focus_font_size: 18px;
$login_selector_focus_icon_size: 28px;
$closed_width: 64px; //Deberian ser 72 pero si no igual no esta para la master porque descuadra todo
$open_width: 315px;
$border_color: #868993;
$profile_pic_size: 27px;
$profile_padding: 15px;

:root {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

#menu-container {
  background-color: var(--color-primary-primary);
  color: var(--color-typography-on-primary);
  font-weight: bold;
  position: fixed;
  z-index: 999;
  top: 0px;
  cursor: pointer;
  height: 100vh;
  transition: width 0.15s ease-in;
  overflow: hidden;
  border-right: var(--menu-border-size) solid #868993;
}

#menu-container-frame {
  width: $open_width;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#login-selector {
  padding: $login_selector_not_focus_padding 0;
  transition: padding, 0.2s;
  transition: font-size, 0.2s;
}

#login-selector:hover {
  padding: $login_selector_focus_padding 0px;
  font-size: $login_selector_focus_font_size;
}

.md-24 {
  font-size: 20px !important;
}

#login-selector:hover .md-24 {
  transition: font-size, 0.2s;
}

#login-selector:hover .md-24 {
  font-size: $login_selector_focus_icon_size !important;
}

#profile-container {
  order: 1;
  border-bottom: 1px solid $border_color;
}

#link-container {
  order: 2;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

#bottom-pinned-container {
  order: 3;
  margin-top: auto;
  border-top: 1px solid $border_color;
}

.menu-closed {
  width: $closed_width;
}

.menu-open {
  width: $open_width;
}

.nav-selector-container {
  height: $selector_height;
}

.profile-selector-container:hover {
  background-color: var(--color-primary-secondary);
  transition: background-color, 0.2s;
}

.nav-selector-container:hover {
  background-color: var(--color-primary-secondary);
  transition: background-color, 0.2s;
}
.nav-selector-container.important-highlight:hover {
  background-color: #00add8;
  transition: background-color, 0.2s;
}
.important-highlight {
  background-color: orange;
}

.nav-link-icon-container, .nav-link-dropdown-icon-container, .profile-pic-container{
  width: $closed_width;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.nav-link-icon {
  line-height: $selector_height !important;
  text-align: center;
}

.nav-link-text-container, .profile-text-container {
  display: inline-block;
  vertical-align: top;
}
.profile-text-container {
  margin-top: 6px;
}
.nav-link-text {
  font-size: 14px;
  line-height: $selector_height !important;
  text-align: center;
}

#notification-container {
  border-top: 1px solid $border_color;
}

.nav-link-icon-container .material-icons-outlined, .nav-link-dropdown-icon-container .material-icons-outlined {
  margin: 0px !important;
}

.selected, .selected:hover {
  background-color: var(--color-accent-primary);
}

.child-selected {
  background-color: var(--color-primary-secondary);
}

.selected-dropdown .nav-link-dropdown-icon-container {
  transform: rotate(-180deg);
}

.nav-link-dropdown-icon-container {
  transition: all .25s ease-out;
  float: right;
}

.nav-dropdown-option {
  background-color: var(--color-primary-900);
  transition: background-color 0.2s;
}

.nav-dropdown-option:hover {
  background-color: var(--color-primary-secondary);
}

.option-selected, .option-selected:hover {
  background-color: var(--color-accent-primary);
}

.nav-dropdown-options-container, .profile-options-container {
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.5s;
}

.dropdown-options-closed {
  height: 0px;
  opacity: 0%;
}

.profile-selector-container {
  padding: $profile_padding 0;
  height: $selector_height;
}

.profile-title-text {
  display: block;
}

.profile-pic-container {
  height: 100%;
}

#profile-pic-center-helper {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.log-out-text {
  color: var(--color-other-error);
}

#log-out-button-container {
  transition: background-color, 0.2s;
}

#log-out-button-container:active {
  background-color: var(--color-accent-primary);
}

#log-out-button-container:active .log-out-text {
  color: var(--color-typography-on-primary) !important;
}

.profile-pic {
  display: inline-block;
  vertical-align: middle;
  //height: $profile_pic_size;
  font-size: $profile_pic_size !important;
}

a {
  text-decoration: unset;
  color: unset;
}
.circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 9px;
}
</style>