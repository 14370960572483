<template>
  <div>
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell--span-4">
        <div class="hello">
          <button class="mdc-button">
            <span class="mdc-button__ripple"></span>
            <span class="mdc-button__label">My Accessible Button</span>
            <span class="mdc-button__touch"></span>
          </button>
          <h1>{{ msg }}</h1>
        </div>
      </div>

      <div class="mdc-layout-grid__cell--span-4">
        <button class="mdc-button">
          <span class="mdc-button__ripple"></span>
          <i class="material-icons mdc-button__icon" aria-hidden="true">bookmark</i>
          <span class="mdc-button__label">Text Button plus icon</span>
          <span class="mdc-button__touch"></span>
        </button>
      </div>

      <div class="mdc-layout-grid__cell--span-4">
        <div class="mdc-switch mdc-switch--checked two">
          <div class="mdc-switch__track"></div>
          <div class="mdc-switch__thumb-underlay">
            <div class="mdc-switch__thumb"></div>
            <input
              type="checkbox"
              id="another-basic-switch2"
              class="mdc-switch__native-control"
              role="switch"
              aria-checked="true"
              checked
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MDCSwitch } from '@material/switch';
import { MDCRipple } from '@material/ripple';

export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
  data() {
    return {
      is_clicked: true,
    };
  },
  mounted() {
    [].map.call(document.querySelectorAll('.mdc-switch'), function(el) { return new MDCSwitch(el); });
    [].map.call(document.querySelectorAll('.mdc-button'), function(el) { return new MDCRipple(el); });
  },
  computed: {
    activate_click() {
      return this.is_clicked;
    },
  },
  methods: {
  }
}
</script>

<style lang="scss">
@use "@material/card";
@use '@material/button/mdc-button';
@use "@material/switch/mdc-switch";
.mdc-card {
  height: 350px;
  width: 350px;
}
@include card.core-styles;
</style>