// mutations.js
export default {

  isMobileDevice(state, payload) {
    state.is_mobile_device = payload
  },

  isTabletDevice(state, payload) {
    state.is_tablet_device = payload
  },

  setSelectedTicker(state, ticker) {
    state.selected_ticker = ticker;
  },

  setArrayFiltersStocks(state, filters) {
    state.array_filters_stocks = filters;
  },

  setArrayFiltersCriptos(state, filters) {
    state.array_filters_criptos = filters;
  },

  setArrayFilters(state, filters) {
    state.array_filters = filters;
  },

  setUser(state, user) {
    state.user = user;
  },

  setUserId(state, user_id) {
    state.user_id = user_id;
  },

  setShowStockFinderFilters(state) {
    state.show_stock_finder_filters = !state.show_stock_finder_filters;
  },
  setShowStockChartMenu(state) {
    state.show_stockchartmenu = !state.show_stockchartmenu;
  },

  setToggleBetweenListAndChartInStockchart(state) {
    state.show_stockchart_list = !state.show_stockchart_list;
  },

  setRefreshTable(state) {
    state.refresh_table = !state.refresh_table;
  },

  setStockchart(state) {
    state.show_stockchart_list = true;
  },

  setStocksTable(state) {
    state.show_stockchart_list = false;
  },

  setSelectedFavoriteWorkspace(state, name) {
    state.workspace_name = name;
  },

  setHandlerToggleViews(state, toggles) {
    state.handler_toggle_views = toggles;
  },

  ['stickymenu'](state) {
    state.sticky_menu = !state.sticky_menu;
  },

  toggleDarkTheme(state, payload) {
    //alert(state.dark_theme);
    state.dark_theme = payload;
    //alert(state.dark_theme);
  },

  setFinderFilters(state, newfinder) {
    state.finder = newfinder;
  },

  setRechargeState(state) {
    state.recharge = !state.recharge;
  },

  setPortfolioList(state, list) {
    state.portfolio_list = list;
  },

  setPortfolioIdSelected(state, portfolio_id_selected) {
    state.portfolio_id_selected = portfolio_id_selected;
  },

  setSidebarRight(state) {
    state.sidebarRight = !state.sidebarRight;
  },

  setStockValue(state, value) {
    state.stock = value;
  },

  setStockPlatinumCompatibilityById(state, {compatibility, id}) {
    const stockIndex = state.stocks.findIndex(stock => {return stock.id == id});
    if(stockIndex != -1) {
      state.stocks[stockIndex].platinum_compatibility = compatibility;
    }
  },

  setMenuSelectionOut(state, index) {
    state.menuSelectionOut = !state.menuSelectionOut;
    state.indexMenu = index;
  },

  setChangesInPortfolio(state) {
    state.changes_in_portfolios = !state.changes_in_portfolios;
  },

  setIsLoadingStocks(state, is_loading_stocks) {
    state.is_loading_stocks = is_loading_stocks;
  },
  
  setStocks(state, stocks) {
    state.stocks = stocks;
  },

  setChartView(state, enabled){
    state.chart_view = enabled;
  },

  setStocksView(state, parameter){
    state.stocks_view = parameter;
  },

  setToggleChartStocks(state, name){
    state.toggleChartStocks = name;
  },

  setOpenInfo(state){
    state.open_info = !state.open_info
  },

  setOpenFundamental(state){
    state.open_fundamental = !state.open_fundamental
  },

  setOpenDescription(state){
    state.open_description = !state.open_description
  },

  setOpenBrain(state){
    state.open_brain = !state.open_brain
  },

  setOpenSimulator(state){
    state.open_simulator = !state.open_simulator
  },

  setOpenComments(state){
    state.open_comments = !state.open_comments
  },

  setOpenNotas(state){
    state.open_notas = !state.open_notas
  },

  setOpenAlarm(state){
    state.open_alarm = !state.open_alarm
  },

  setOpenError(state){
    state.open_error = !state.open_error
  },

  setOpenOrder(state){
    state.open_order = !state.open_order
  },

  closeOtherButtons(state, name){
    if(name == "info"){
      state.open_fundamental = false;
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "description"){
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "brain"){
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "simulator"){
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "comments"){
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "notas"){
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_error = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "error"){
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_order = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "filters"){
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_error = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_order = false;
      state.open_alarm = false;
    } else if(name == "favorites"){
      state.open_filters = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_order = false;
      state.open_error = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "searches"){
      state.open_favorites = false;
      state.open_filters = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_order = false;
      state.open_error = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    } else if(name == "fundamental"){
      state.open_favorites = false;
      state.open_searches = false;
      state.open_filters = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_info = false;
      state.open_order = false;
      state.open_error = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    }else if(name == "alarms"){
      state.open_fundamental = false;
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_info = false;
      state.show_stock_finder_filters = false;
    }else if(name == "descriptionSearch") {
      state.open_fundamental = false;
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_order = false;
      state.open_info = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    }
    else{
      state.open_filters = false;
      state.open_favorites = false;
      state.open_searches = false;
      state.open_description = false;
      state.open_brain = false;
      state.open_simulator = false;
      state.open_comments = false;
      state.open_notas = false;
      state.open_error = false;
      state.open_info = false;
      state.open_fundamental = false;
      state.open_alarm = false;
      state.show_stock_finder_filters = false;
    }
  },
  
  setOptionMobile(state) {
    state.option_mobile = !state.option_mobile;
  },

  setOpenFilters(state){
    state.open_filters = !state.open_filters;
  },

  setOpenFavorites(state){
    state.open_favorites = !state.open_favorites;
  },

  setOpenSearches(state){
    state.open_searches = !state.open_searches;
  },

  addActiveOverlay(state) {
    state.active_overlays++;
  },

  substractActiveOverlay(state) {
    state.active_overlays--;
  },

  /*setHasToRefreshToken(state) {
    state.has_to_refresh_token = true;
  },*/ //TODO a eliminar si no dependemos de oauth

  setActiveRightComponent(state, active_component) {
    state.active_right_component = active_component;
  },

  /*unsetHasToRefreshToken(state) {
    state.has_to_refresh_token = false;
  },*/ //TODO a eliminar si no dependemos de oauth

  setCrypto(state) {
    state.crypto_enable = !state.crypto_enable;
  },

  setUserInfo(state, info) {
    state.user_info = info;
  },

  setOrderBy(state, order){
    state.order_by = order;
  },

  setWorkspaceCreated(state, value){
    state.workspace_created = value
  },

  setTickersFinder(state){
    state.tickers_finder = !state.tickers_finder;
  },

  setUserFeatures(state, user_features){
    state.user_features = user_features;
  },

  setUserRoles(state, user_features) {
    state.user_features.is_bronze_user = user_features.is_bronze_user;
    state.user_features.is_silver_user = user_features.is_silver_user;
    state.user_features.is_gold_user = user_features.is_gold_user;
    state.user_features.is_platinum_user = user_features.is_platinum_user;
    state.user_features.is_pre_eureker_user = user_features.is_pre_eureker_user;
    state.user_features.is_basic_user = user_features.is_basic_user;
    state.user_features.has_active_role = state.user_features.is_bronze_user || state.user_features.is_silver_user || state.user_features.is_gold_user || state.user_features.is_platinum_user || state.user_features.is_pre_eureker_user || state.user_features.is_basic_user;
  }
}