<template>
  <div v-if="user_has_closed != 'true'" class="banner-container">
    <div class="banner-container">
      <p style="margin-left: 220px;">¡NUEVA MASTERCLASS! <strong>El 27 de Marzo a las 20:00</strong> (hora peninsular) tienes una cita con Eurekers. ¡No te lo pierdas!.</p>
      <span class="material-icons-outlined close-icon-banner" @click="_closeBanner()">close</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
name: 'Banner',
mixins: [APICaller],
data() {
  return {
    user_has_closed: 'true',
  };
},
watch: {
  
},
mounted() {
  var bannerInterval = setInterval(() => {
    if (this._g_UserId != undefined && this._g_UserId != 0 && this._g_UserId != null) {
      this._getIfUserClosedBanner();
      clearInterval(bannerInterval);
    }
  }, 250);
},
computed: {
  ...mapGetters(['_g_IsUserLogged', '_g_UserId'])
},
methods: {
  _getIfUserClosedBanner: function(){
    this.user_has_closed = sessionStorage.getItem('masterclass-banner');
  },

  _closeBanner: function(){
    this.user_has_closed = "true"
    sessionStorage.setItem('masterclass-banner', 'true');
  }
},
}
</script>

<style lang="scss">
.banner-container{
display: flex;
align-items: center;
justify-content: space-between;
}
.close-icon-banner{
margin-left: 177px;
}
</style>
