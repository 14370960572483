// getters.js
export default {
    _g_SelectedTicker: state => state.selected_ticker,

    _g_User: state => state.user,

    _g_UserId: state => state.user_id,

    _g_IsUserLogged: state => (state.user_id != 0 && (state.user.is_basico_off != true && state.user.is_avanzado_off != true)),

    _g_ArrayFiltersStocks: state => state.array_filters_stocks,

    _g_ArrayFiltersStocksDefault: state => state.array_filters_stocks_default,

    _g_ArrayFiltersCriptos: state => state.array_filters_criptos,

    _g_ArrayFiltersCriptosDefault: state => state.array_filters_criptos_default,

    _g_ArrayFilters: state => state.array_filters,

    _g_SelectedWorkspaceFavorite: state => state.workspace_name,

    _g_ShowStockFinderFilter: state => state.show_stock_finder_filters,

    _g_ToggleBetweenTableAndChart: state => state.show_stockchart_list,

    is_mobile_device: state => state.is_mobile_device,

    is_tablet_device: state => state.is_tablet_device,

    _g_StickyMenu: state => state.sticky_menu,

    _g_ThemeName: state => state.dark_theme ? 'dark-theme' : 'light-theme',

    _g_HandlerToggleViews: state => state.handler_toggle_views,

    _g_ShowStockChartMenu: state => state.show_stockchartmenu,

    _g_FinderFilters: state => state.finder,

    _g_RechargeState: state => state.recharge,

    _g_ArrayPortfoliosUser: state => state.portfolio_list,

    _g_portfolio_id_selected : state => state.portfolio_id_selected,

    _g_SidebarRight: state => state.sidebarRight,

    _g_GetTypeStocks: state => state.stock,

    _g_MenuSelectionOut: state => state.menuSelectionOut,

    _g_IndexMenu: state => state.indexMenu,

    _g_ChangesInPortfolio: state => state.changes_in_portfolios,

    _g_RefreshTable: state => state.refresh_table,

    _g_Stocks: state => state.stocks,

    _g_is_loading_stocks: state => state.is_loading_stocks,

    _g_chart_view: state => state.chart_view,

    _g_stocks_view: state => state.stocks_view,

    _g_toggle_chart_stocks: state => state.toggleChartStocks,

    _g_open_filters: state => state.open_filters,

    _g_open_favorites: state => state.open_favorites,

    _g_open_searches: state => state.open_searches,

    _g_open_info: state => state.open_info,

    _g_open_fundamental: state => state.open_fundamental,

    _g_open_description: state => state.open_description,

    _g_open_brain: state => state.open_brain,

    _g_open_simulator: state => state.open_simulator,

    _g_open_order: state => state.open_order,

    _g_open_notas: state => state.open_notas,

    _g_open_alarms: state => state.open_alarms,

    _g_open_error: state => state.open_error,

    _g_open_comments: state => state.open_comments,

    _g_option_mobile: state => state.option_mobile,

    _g_ActiveOverlays: state => state.active_overlays,

    _g_Active_right_component: state => state.active_right_component,

    _g_HasToRefreshToken: state => state.has_to_refresh_token,

    _g_crypto_enable : state => state.crypto_enable,

    _g_user_info : state => state.user_info,

    _g_order_by: state => state.order_by,

    _g_workspace_created: state => state.workspace_created,

    _g_TickersFinder: state => state.tickers_finder,

    _g_UserFeatures: state => state.user_features,
}