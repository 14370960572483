<template>
    <div v-show="this.show" id="overlay-container">
        <div id="overlay-background"></div>
        <div id="overlay-image-container">
            <img id="overlay-image" src="@/assets/img/load-icon.gif">
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'LoadingOverlay',
  data() {
    return {
        icon_path: "@/assets/img/load-icon.gif",
        show: false,
        activeOverlayCalls: 0,
        firstWatchActivated: false,
        currentStartTimeout: null,
        currentStopTimeout: null,
        showMsDelayWhenToggledOn: 500,
        showMsDelayWhenToggledOff: 200,
    };
  },
  watch: {
    //Makes gif start from the beginning
    show(oldVal, newVal) {
      // Turns on; off -> on
      if (!oldVal && newVal) {
        //document.getElementById("overlay-image").setAttribute('src', this.icon_path);
        let img = document.getElementById("overlay-image");
        img.src = `${img.src.replace(/\?.*$/,"")}?x=${Math.random()}`;
      }
      // Turns off; on -> off
      else if(oldVal && !newVal) {
        //document.getElementById("overlay-image").setAttribute('src', '');
      }
    },
    _g_ActiveOverlays(newVal, oldVal) {

      this.firstWatchActivated = true;
      this.processCumulativeActiveOverlays(newVal);
    }
  },
  mounted() {
    if (!this.firstWatchActivated) {
      this.processCumulativeActiveOverlays(this._g_ActiveOverlays);
    }
  },
  computed: {
    ...mapGetters(['_g_ActiveOverlays'])
  },
  methods: {
    startLoadingOverlay(activated_overlays = 1) {
      let toggleOn = (function() {this.show = true; this.currentStartTimeout = null; document.getElementsByTagName("html")[0].classList.add("noscroll"); document.getElementsByTagName("body")[0].classList.add("noscroll");}).bind(this);

      if (this.activeOverlayCalls == 0) {
        if (this.show == false) {
          this.currentStartTimeout = setTimeout(toggleOn, this.showMsDelayWhenToggledOn);
        }
        if (this.currentStopTimeout != null) {
          clearTimeout(this.currentStopTimeout);
          this.currentStopTimeout = null;
        }
      }

      this.activeOverlayCalls += activated_overlays;
    },

    stopLoadingOverlay(deactivated_overlays = 1) {
      let toggleOff = (function() {this.show = false; this.currentStopTimeout = null; document.getElementsByTagName("html")[0].classList.remove("noscroll"); document.getElementsByTagName("body")[0].classList.remove("noscroll"); }).bind(this);
      
      this.activeOverlayCalls = this.activeOverlayCalls > 0 + deactivated_overlays ? this.activeOverlayCalls - deactivated_overlays : 0;

      if (this.activeOverlayCalls == 0) {
        if (this.show == true) {
          this.currentStopTimeout = setTimeout(toggleOff, this.showMsDelayWhenToggledOff);
        }
        if (this.currentStartTimeout != null) {
          clearTimeout(this.currentStartTimeout);
          this.currentStartTimeout = null;
        }
      }
    },

    timedLoadingOverlay(timer) {
      this.startLoadingOverlay();
      setTimeout((this.stopLoadingOverlay()).bind(this),timer);
    },

    processCumulativeActiveOverlays(overlays_active_on_db) {
      const dif = overlays_active_on_db - this.activeOverlayCalls;

      if(dif > 0) {
        this.startLoadingOverlay(dif);
      }
      else if (dif < 0) {
        this.stopLoadingOverlay(-dif);
      }
    }
  },
}
</script>

<style lang="scss">
  #overlay-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #overlay-background {
    background-color: black;
    position: fixed;
    height: 100%;
    width: 100%;
    opacity: 0.3;
  }
  #overlay-image-container {
    position: relative;
    z-index: 101;
  }
  #overlay-image {
    width: 100px;
    height: 100px;
  }
</style>