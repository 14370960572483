import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import axios from "axios";

Vue.use(VueRouter)

const routes = [
    {
        path: '/app',
        name: 'Landing',
        beforeEnter: checkLogged
    },
    {
        path: '/app/login',
        name: 'Login',
        component: () =>
        import ('@/views/Login.vue'),
    },
    {
        path: '/app/home',
        name: 'Home',
        component: Home,
    },
    {
        path: '/app/store',
        name: 'Marketplace',
        component: () =>
        import('@/views/Store.vue')
    },
    {
        path: '/app/graficador-dinamico',
        component: () =>
        import ('@/views/Stockchart/Base.vue'),
        children: [{
            path: '',
            name: 'Stockchart',
            component: () =>
            import ('@/views/Stockchart/Stockchart.vue'),
        },
            {
            path: 'stockchart-table',
            name: 'StockchartTable',
            component: () =>
            import ('@/views/Stockchart/Stockchart.vue'),
        }
        ],
    }, 
    {
        path: '/app/graficador-dinamico/:param1',
        component: () =>
        import ('@/views/Stockchart/Base.vue'),
        children: [{
            path: '',
            name: 'Stockchart',
            component: () =>
            import ('@/views/Stockchart/Stockchart.vue'),
        },
            {
            path: 'stockchart-table',
            name: 'StockchartTable',
            component: () =>
            import ('@/views/Stockchart/Stockchart.vue'),
        }
        ],
    },
    {
        path: '/app/graficador-dinamico/:param1/:param2',
        component: () =>
        import ('@/views/Stockchart/Base.vue'),
        children: [{
            path: '',
            name: 'Stockchart',
            component: () =>
            import ('@/views/Stockchart/Stockchart.vue'),
        },
            {
            path: 'stockchart-table',
            name: 'StockchartTable',
            component: () =>
            import ('@/views/Stockchart/Stockchart.vue'),
        }
        ],
    },
    {
        path: '/app/portfolio',
        name: 'Portfolio',
        component: () =>
        import ('@/views/Portfolio.vue')
    },{
        path: '/app/portfolio/:param1/:param2',
        name: 'Portfolio',
        component: () =>
        import ('@/views/Portfolio.vue')
    },{
        path: '/app/artificial-inteligence',
        name: 'InteligenceArtificial',
        component: () =>
        import ('@/views/InteligenceArtificial.vue')
    }, {
        path: '/app/twinsouls',
        name: 'TwinSouls',
        component: () =>
        import ('@/views/TwinSouls.vue')
    }, {
        path: '/app/directionality',
        name: 'Directionality',
        component: () =>
        import ('@/views/Directionality.vue')
    }, {
        path: '/app/forum',
        name: 'Forum',
        component: () =>
        import ('@/views/Foro.vue')
    },  {
        path: '/app/stoploss',
        name: 'StopLoss',
        component: () =>
        import ('@/views/StopLoss.vue')
    },  {
        path: '/app/risk',
        name: 'Risk',
        component: () =>
        import ('@/views/Risk.vue')
    }, {
        path: '/app/widgets',
        name: 'Widgets',
        component: () =>
        import ('@/views/Widgets.vue')
    }, {
        path: '/app/exercises',
        name: 'Exercises',
        component: () =>
        import ('@/views/Exercises.vue')
    }, {
        path: '/app/courses',
        name: 'Courses',
        component: () =>
        import ('@/views/Courses.vue')
    }, {
        path: '/app/help-center',
        name: 'HelpCenter',
        component: () =>
        import ('@/views/HelpCenter.vue')
    }, {
        path: '/app/broker-simulator',
        name: 'BrokerSimulator',
        component: () =>
        import ('@/views/BrokerSimulator.vue')
    }, {
        path: '/app/pricing',
        name: 'Pricing',
        component: () =>
        import ('@/views/Pricing.vue')
    }, {
        path: '/app/preferences',
        name: 'Preferences',
        component: () =>
        import ('@/views/Preferences.vue')
    }, {
        path: '/app/InvestmentCalculator',
        name: 'InvestmentCalculator',
        component: () => 
        import ('@/views/InvestmentCalculator.vue')
    }, {
      path: '/app/my-profile',
      name: 'MyProfile',
      component: () =>
      import ('@/views/MyProfile.vue')
    },
    {
        path: '/app/change-password',
        name: 'Password',
        component: () =>
        import ('@/views/Password.vue')
    },
    {
        path: '/app/retrieve-password',
        name: 'Password',
        component: () =>
        import ('@/views/RetrievePassword.vue')
    },
    {
        path: '/app/compoundInterestCalculator',
        name: 'Calculadora de interés compuesto',
        component: () =>
        import ('@/views/CompoundInterestCalculator.vue')
    },
    {
        path: '/app/calculadora-autonomia-financiera',
        name: 'Calculadora de autonomía financiera',
        component: () =>
        import ('@/views/RetirementCalculator.vue')
    },
    {
        path: '/app/inflationCalculator',
        name: 'Calculadora de inflación',
        component: () =>
        import ('@/views/InflationCalculator.vue')
    },
    {
        path: '/app/calculadora-creador-cartera',
        name: 'Calculadora de cartera',
        component: () => 
        import ('@/views/PortfolioCreatorCalculator.vue')
    },{  
        path: '/app/store',
        name: 'Tienda',
        component: () => 
        import ('@/views/Store.vue')
    },{  
        path: '/app/cart',
        name: 'Cesta',
        component: () => 
        import ('@/views/Cart.vue')
    },{  
        path: '/app/informacion-bolsa',
        name: 'InfoBolsa',
        component: () => 
        import ('@/views/InfoBolsa.vue')
    },{
        path: '/app/stock/:ticker',
        name: 'Valores',
        component: () => 
        import ('@/views/Valores.vue')
    },{
        path: '/app/portfolio/invest-preferences',
        name: 'InvestPreferences',
        component: () =>
        import ('@/views/Portfolio_preferences.vue')
    },{
        path: '/app/portfolio/balance/:idc',
        name: 'Portfolio Balance',
        component: () =>
        import ('@/views/Portfolio_balance.vue')
    },{
        path: '/app/portfolio/historic/:idc',
        name: 'Portfolio Historic',
        component: () =>
        import ('@/views/Portfolio_historic.vue')
    },{
        path: '/app/portfolio/general/:idc',
        name: 'Portfolio General',
        component: () =>
        import ('@/views/Portfolio_general.vue')
    },{
        path: '/app/portfolio/wait-list/:idc',
        name: 'Portfolio WaitList',
        component: () =>
        import ('@/views/Portfolio_waitlist.vue')
    },{
        path: '/app/portfolio/open-positions/:idc',
        name: 'Portfolio open Positions',
        component: () =>
        import ('@/views/Portfolio_openpositions.vue')
    },{
        path: '/app/videos',
        name: 'Videos',
        component: () =>
        import ('@/views/Videos.vue')
    },{
        path: '/app/profile/certificates/:uid?',
        name: 'UserCertificates',
        component: () =>
        import ('@/views/Certificates.vue')
    },{
        path: '/app/profile/user-photo',
        name: 'UserPhoto',
        component: () =>
        import ('@/views/User-photo.vue')
    },{
        path: '/app/profile/bills',
        name: 'UserBills',
        component: () =>
        import ('@/views/Bills.vue')
    },{
        path: '/app/nuevas-ipos',
        name: 'NewsIPOs',
        component: () =>
        import ('@/views/News_ipos.vue')
    },{
        path: '/app/crear-notificacion',
        name: 'CrearNotificacion',
        beforeEnter: checkAdminRights,
        component: () =>
        import ('@/views/Crear_notificacion.vue')
    },{
        path: '/app/session-control',
        name: 'SessionControl',
        beforeEnter: checkAdminRights,
        component: () =>
        import ('@/views/Session_control.vue')
    },{
        path: '/app/eventos',
        name: 'Eventos',
        beforeEnter: checkAdminRights,
        component: () =>
        import ('@/views/Eventos.vue')
    },{
        path: '/app/mentorias',
        name: 'Mentorias',
        beforeEnter: checkTutor,
        component: () =>
        import ('@/views/Mentorias/Mentorias.vue')
    },{
        path: '/app/mentorias/admin',
        name: 'Admin mentoring view',
        component: () =>
        import ('@/views/Mentorias/Admin_mentorias.vue')
    },{
        path: '/app/curso-online/login',
        name:'Login Curso Online',
        component: () =>
        import ('@/views/FakeLoginToCO.vue')
    },{
        path: '/app/eurekers-school',
        name:'Eurekers School',
        component: () =>
        import ('@/views/EurekersSchool.vue')
    },{
        path: '/app/user-register',
        name:'Registro de Usuario',
        component: () =>
        import ('@/views/RegisterForm.vue')
    },{
        path: '/app/account-verification',
        name:'Verificación de Cuenta',
        component: () =>
        import ('@/views/AccountVerification.vue')
    },{
        path:'/app/product-confirmation/gracias',
        name:'Confirmacion de compra',
        component: () =>
        import ('@/views/ProductConfirmation.vue')
    },
    {
        path:'/app/product-confirmation/error',
        name:'Error de compra',
        component: () =>
        import ('@/views/ProductConfirmationError.vue')
    },
    {
        path: '/app/store/course/form-waitlist/:country',
        name: 'Form Waitlist Courses',
        component: () =>
        import ('@/views/Form_waitlist_courses.vue')
    },
    {
        path: '/app/libros-de-bolsa',
        name: 'LibrosBolsa',
        component: () =>
        import ('@/views/LibrosBolsa.vue')
    },
    // {
    //     path: '/app/new-forum',
    //     name: 'NewForum',
    //     component: () =>
    //     import ('@/views/NewForum.vue')
    // },
    // {
    //     path: '/app/new-forum/thread/:nid',
    //     name: 'NewForumThread',
    //     component: () =>
    //     import ('@/views/NewForumThread.vue')
    // },
    // {
    //     path: '/app/new-forum/search',
    //     name: 'NewForumSearch',
    //     component: () =>
    //     import ('@/views/ForumSearch.vue')
    // }

]

const router = new VueRouter({
    mode: 'history',
    routes
})
async function checkAdminRights(to, from, next) {
    let isAdmin = await _getUserInfo();
    isAdmin = isAdmin.data.is_developer_user;
    if(isAdmin) {
        next();       
    } else {
        next({ path: '/app/graficador-dinamico'});
    }
}

async function checkLogged(to, from, next) {
    let isLogged = await _getUserInfo();
    isLogged = isLogged.data.uid !== 0;
    if(isLogged) {
        next({ path: '/app/login'});       
    } else {
        next({ path: '/app/graficador-dinamico'});
    }
}

async function checkTutor(to, from, next){
    let user = await _getUserInfo();
    if(user.data.uid == "8690"){
        next({ path: '/app/mentorias/admin'});
    }else{
        next();
    }
}

function _getUserInfo(){
    return axios.get(process.env.VUE_APP_API + "/api/v1/users/user-attributes/" + localStorage.getItem("uid"));
}
export default router