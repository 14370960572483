<template>
<div id="notifications" style="position: absolute; top: 0px; left: 64px; height: 100%; width: 402px;">
  <div class="header-notifications">
    <span></span>
    <span class="title-header typo-headline-6">Notificaciones</span>
    <span class="material-icons-outlined close-component" @click="_closeNotificationContainer">close</span>
  </div>
  <div style="overflow-y: scroll; max-height: 94%">
    <div v-for="(item, index) in notifications" :key="index">
      <div v-if="item.status == '0'" class="notification-container not-readed-notification">
        <div class="circle-date-container">
          <span class="material-icons" style="color: #df2f4f; font-size: 14px;">circle</span>
          <span class="custom-typo-body-2">{{_timeConverter(item.date)}}</span>
        </div>
        <div class="notifications-title-container">
          <span class="body-title typo-headline-6">{{item.title}}</span>
        </div>
        <div class="body-container">
          <span class="typo-body-2 body-text" v-html="item.body.body"></span>
        </div>
      </div>
      <div v-if="item.status == '1'" class="notification-container readed-notification">
        <div class="date-container">
          <span class="custom-typo-body-2">{{_timeConverter(item.date)}}</span>
        </div>
        <div class="notifications-title-container">
          <span class="body-title typo-headline-6" style="color: rgb(0, 0, 16, 0.6)">{{item.title}}</span>
        </div>
        <div class="body-container">
          <span class="custom-typo-body-2 body-text" v-html="item.body.body"></span>
        </div>
      </div>
    </div>
  </div>

</div>
</template>



<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { ConsoleWriter } from "istanbul-lib-report";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller],
  name: 'Notifications',
  components: {
  },
  props: {
  },
  data: () => ({
    notifications: [],
  }),
  mounted() {
    var waiting_for_user_id = setInterval(()=>{
      if(this._g_UserId && this._g_UserId != undefined && this._g_UserId != null){
        this._getAllUserNotifications()
        clearInterval(waiting_for_user_id)
      }
    },200)
  },
  created: function() {
    
  },
  watch: {
    
  },
  computed: {
    ...mapGetters(['_g_UserId']),
  },
  methods: {
    _closeNotificationContainer: function(){
      this.$emit("close_notification_container_from_component")
    },

    _readNotifications: function(){
      let success = response => {
        this.$emit("read_notification_from_component");
      }

      let url = "/api/v1/eurekers-notifications/read-notifications/" + this._g_UserId;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('315', 'Error cerrando notificaciones');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getAllUserNotifications: function(){
      let success = response => {
        this.notifications = response.data;
        this.notifications.forEach(element=>{
          element.body = JSON.parse(element.body);
          if(element.status == 0){
            element.body.script = eval(element.body.script);
          }
        })
        this.notifications = this._checkTimestamps(response.data);
        this.notifications = this.notifications.sort((a,b) => (a.date < b.date) ? 1 : -1)
        this._readNotifications();
      }

      let url = "/api/v1/eurekers-notifications/" + this._g_UserId;
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('316', 'Error recuperando notificaciones');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _timeConverter: function(UNIX_timestamp){
      if(UNIX_timestamp == 0){
        return "Fecha no disponible"
      }else{
        let a = new Date(UNIX_timestamp * 1000);
        let year = a.getFullYear();
        let month = a.getMonth() + 1;
        let date = a.getDate();
        let time = date + '/' + month + '/' + year;
        return time;
      }
    },
    _checkTimestamps: function(arr) {
      var notifications = [];
      var today = new Date().getTime(); // get current timestamp
      for(var i = 0; i < arr.length; i++) {
        if(arr[i].date*1000 <= today) {
          notifications.push(arr[i]);
        }
      }
      return notifications;
    },    
  },
}
</script>


<style lang="scss" scoped>
@import "@/styles/basics/globals.scss";

#notifications {
  background-color: var(--color-background);
}

.notification-container {
  border: 1px solid var(--color-outline);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  background-color: var(--color-background);
}

.circle-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.date-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.go-to-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.readed-notification {
  background: var(--color-muted);
  border-left: 4px solid var(--color-muted);
}

.not-readed-notification {
  background: var(--color-background);
  border-left: 4px solid var(--color-error);
}

.header-notifications {
  background: var(--color-background);
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid var(--color-outline);
}

.title-header {
  color: var(--color-text-primary);
  margin-top: 0;
  font-family: 'Open Sans', sans-serif;
}

.close-component {
  color: var(--color-text-secondary);
  margin-top: 0;
  margin-right: 5px;
  cursor: pointer;
}

.close-component:hover {
  color: var(--color-text-primary);
}

.body-container {
  padding: 7px 30px 15px 30px;
}

.notifications-title-container {
  padding: 5px 30px 0 30px;
}

.custom-typo-body-2 {
  color: var(--color-text-secondary);
  line-height: 17px;
  font-size: 14px;
  font-family: 'Open Sans', 'sans-serif';
  letter-spacing: 0.25px;
}

.body-title {
  color: var(--color-text-secondary) !important;
}

.body-text {
  color: var(--color-text-secondary);
}

.body-text ::v-deep a {
  color: var(--color-highlight);
  text-decoration: underline;
}

/* Scrollbar styling */
#notifications > div {
  overflow-y: auto;
  max-height: 94%;
}

#notifications > div::-webkit-scrollbar {
  width: 8px;
}

#notifications > div::-webkit-scrollbar-thumb {
  background-color: var(--color-outline);
  border-radius: 4px;
}

#notifications > div::-webkit-scrollbar-track {
  background: transparent;
}
</style>