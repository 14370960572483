<template>
  <div v-if="show" class="popup-overlay" @click="closeOnOverlay">
    <div class="popup-content">
      <span class="close-button" @click="close">
        <span class="material-icons material-icons-outlined">close</span>
      </span>
      
      <div class="popup-text">
        <p class="title">En Eurekers seguimos trabajando para ti ✨</p>
        <p class="highlight-popup">¡El modo oscuro ha llegado al Graficador Dinámico! 🌑</p>
        <p>Ahora puedes disfrutar de una experiencia más cómoda para la vista con el nuevo modo oscuro en el Graficador Dinámico.</p>
        <p>Actívalo en la parte inferior izquierda de la pantalla.</p>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  name: 'DarkModePopup',
  mixins: [APICaller],
  data() {
    return {
      show: false
    }
  },
  mounted() {
    this.checkIfShouldShow();
  },
  methods: {
    checkIfShouldShow() {
      let success = response => {
        this.show = !response.data;
      }

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('315', 'Error al comprobar el estado del popup.');

      this._getAPICall('/api/v1/check-modal', successHandler, failureHandler);
    },
    close() {
      this.show = false;
    },
    closeOnOverlay(e) {
      if (e.target.className === 'popup-overlay') {
        this.close();
      }
    }
  }
}
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-overlay {
  text-align:center;
}

.popup-content {
  background: var(--color-surface);
  border: 1px solid var(--color-outline);
  padding: 30px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 90%;
  color: var(--color-text-primary);
}

.close-button {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  color: var(--color-text-secondary);
}

.close-button:hover {
  color: var(--color-text-primary);
}

.popup-text {
  margin-top: 10px;
}

.popup-text p {
  margin: 15px 0;
  line-height: 1.5;
}

.title {
  font-size: 1.2em;
  font-weight: 600;
  color: var(--color-text-primary);
}

.highlight-popup {
  font-size: 1.1em;
  font-weight: 500;
  color: var(--color-highlight);
}
</style> 