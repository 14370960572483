<template>
  <div class="mdc-layout-grid__inner">
    <div class="mdc-layout-grid__cell--span-12">
      <HelloWorld msg="Vue.js App" />

      <States />

      <input type="number" @input="incrementCounter" v:input="updateCounter" />
    </div>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import States from '@/components/States.vue'
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Home',
  components: {
    States,
    HelloWorld,
  },
  mounted() {
    this.$store.dispatch('increment'); // better from this action =>this.$store.commit('increment');

    this.$store.dispatch('actionMain').then(() => {});
  },
  computed: {
    ...mapState(['getUpdateCounter']),
  },
  methods: {
    ...mapMutations(['updateCounter']),

    incrementCounter(event) {
      this.updateCounter(event.target.value);
    }
  },
}
</script>



<style lang="scss" scoped>
  p {color:blue}
</style>
