export default function authHeader() {
  let user_access_token = localStorage.getItem('user_access_token');

  if (user_access_token) {
    return {
      'authorization': 'Bearer ' + user_access_token,
    };
  } else {
    return {};
  }

}