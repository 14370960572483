import axios from "axios";
import store from '@/store'
import VueOnToast from 'vue-on-toast'

const methodGET = 'get';
const methodPOST = 'post';

const APICallerConfig = {
    defaultTimeoutMs: 10000,
    defaultLoadingOverlay: false,
    defaultDisplayErrors: true,
    defaultLogErrors: true,
    errorTextTemplate: "${errorDescription}\nPor favor, contacte con nosotros si el error persiste"
}

class SuccessHandler {
    constructor(successCallback) {
        this._successCallback = successCallback;
        this._hasCallback = typeof successCallback === 'function';
    }

    get successCallback() {
        return this._successCallback;
    }

    get hasCallback() {
        return this._hasCallback;
    }
}

class FailureHandler {
    constructor(errorCode, errorDescription, errorCallback) {
        this._errorCode = errorCode;
        this._errorDescription = errorDescription;

        if (!errorCode || !errorDescription) {
            throw new Error('Specify error code and description.');
        }

        this._errorCallback = errorCallback;
        this._hasCallback = typeof errorCallback === 'function';
    }

    get errorCode() {
        return this._errorCode;
    }

    get errorDescription() {
        return this._errorDescription;
    }

    get errorCallback() {
        return this._errorCallback;
    }

    get hasCallback() {
        return this._hasCallback;
    }
}

class ErrorContext {
    constructor(back_error, front_error, url, params, callType, timestamp) {
        this._back_error = back_error;
        this._front_error = front_error;
        this._url = url;
        this._params = params;
        this._callType = callType;
        this._timestamp = timestamp;
    }

    get context_json() {
        const context_object = {
            back_error: this._back_error,
            front_error: this._front_error,
            url: this._url,
            callType: this._callType,
            params: this._params,
            timestamp: this._timestamp
        };

        return JSON.stringify(context_object);
    }
}

export default {
    SuccessHandler,
    FailureHandler,
    methods: {
        _getAPICall: function (url, successHandler, failureHandler, { loadingOverlay = APICallerConfig.defaultLoadingOverlay, timeoutMs = APICallerConfig.defaultTimeoutMs, displayErrors = APICallerConfig.defaultDisplayErrors, logErrors = APICallerConfig.defaultLogErrors } = {}) {
            return APICall(methodGET, url, successHandler, failureHandler, { loadingOverlay: loadingOverlay, timeoutMs: timeoutMs, displayErrors: displayErrors, logErrors: logErrors });
        },

        _postAPICall: function (url, params, successHandler, failureHandler, { loadingOverlay = APICallerConfig.defaultLoadingOverlay, timeoutMs = APICallerConfig.defaultTimeoutMs, displayErrors = APICallerConfig.defaultDisplayErrors, logErrors = APICallerConfig.defaultLogErrors } = {}) {
            return APICall(methodPOST, url, successHandler, failureHandler, { params: params, loadingOverlay: loadingOverlay, timeoutMs: timeoutMs, displayErrors: displayErrors, logErrors: logErrors });
        }
    }
}

function APICall(method, url, successHandler, failureHandler, { params = {}, loadingOverlay = APICallerConfig.defaultLoadingOverlay, timeoutMs = APICallerConfig.defaultTimeoutMs, displayErrors = APICallerConfig.defaultDisplayErrors, logErrors = APICallerConfig.defaultLogErrors } = {}) {
    if (!url) {
        throw new Error('An url must be provided.');
    }
    else if (!(successHandler instanceof SuccessHandler) || !(failureHandler instanceof FailureHandler)) {
        throw new Error('Both a vaild SuccessHandler and FailureHandler must be provided.');
    }
    if (method === methodPOST && params === {}) {
        throw new Error('POST calls must send something.')
    }

    if (loadingOverlay) {
        loadingOverlayStartSignal();
    }

    const axiosConfig = {};
    axiosConfig.method = method;
    if (method === methodPOST) {
        axiosConfig.data = params;
    }

    const timeoutEnabled = timeoutMs != 0;
    let timeout;
    if (timeoutEnabled) {
        const cancelTokenSource = axios.CancelToken.source();
        axiosConfig.cancelToken = cancelTokenSource.token;
        timeout = setTimeout(() => { cancelTokenSource.cancel() }, timeoutMs);
    }


    const urlIsInternal = url[0] === '/';
    if (urlIsInternal) {
        axiosConfig.url = process.env.VUE_APP_API + url;
    }
    else {
        axiosConfig.url = url;
    }

    return axios(axiosConfig)
        .then(
            response => {
                if (successHandler.hasCallback) {
                    successHandler.successCallback(response);
                }
            }
        )
        .catch(
            error => {
                if (error && Object.keys(error).length != 0) {
                    const requestAborted = JSON.stringify(error) === '{}';
                    if(requestAborted) {
                        error.message = 'Timeout/Request cancelled error';
                    }
                    else {
                        if (failureHandler.hasCallback) {
                            failureHandler.errorCallback(error);
                        }
                        if (displayErrors && error.response.status != 403) {
                            const errorTextFormatted = APICallerConfig.errorTextTemplate.replace('${errorCode}', failureHandler.errorCode).replace('${errorDescription}', failureHandler.errorDescription);
                            VueOnToast.ToastService.pop('error', `Error ${failureHandler.errorCode}`, errorTextFormatted);
                        }
                    }
                    // if (logErrors) {
                    //     const params_string = formatSearchParams(params);
                    //     const front_error = failureHandler.errorCode + " - " + failureHandler.errorDescription;
                    //     const errContext = new ErrorContext(error.message, front_error, axiosConfig.url, params_string, method, Date.now());

                    //     const errSHandler = new SuccessHandler();
                    //     const errFHandler = new FailureHandler('311', 'Error en el registro');
                    //     const errConfig = { loadingOverlay: false, displayErrors: false, logErrors: false, timeoutMs: 0 };
                    //     const errParams = new URLSearchParams();
                    //     errParams.append('context', errContext.context_json);
                    //     const errUrl = '/api/v1/api-error-logging';

                    //     _postAPICall(errUrl, errParams, errSHandler, errFHandler, errConfig);
                    // }
                }
            }
        )
        .finally(
            () => {
                if (timeout) { clearTimeout(timeout); }
                if (loadingOverlay) {
                    loadingOverlayStopSignal();
                }
            }
        );
}


function loadingOverlayStartSignal() {
    store.commit('addActiveOverlay');
}

function loadingOverlayStopSignal() {
    store.commit('substractActiveOverlay');
}

function _postAPICall(url, params, successHandler, failureHandler, { loadingOverlay = APICallerConfig.defaultLoadingOverlay, timeoutMs = APICallerConfig.defaultTimeoutMs, displayErrors = APICallerConfig.defaultDisplayErrors, logErrors = APICallerConfig.defaultLogErrors } = {}) {
    return APICall(methodPOST, url, successHandler, failureHandler, { params: params, loadingOverlay: loadingOverlay, timeoutMs: timeoutMs, displayErrors: displayErrors, logErrors: logErrors });
}

function formatSearchParams(searchParams) {
    let resultString = '';
    if (searchParams instanceof URLSearchParams) {
        searchParams.forEach((value, key) => {
            if (typeof value === "object") {
                resultString += `${key}: `;
                resultString += formatObject(value, 1);
            } else {
                resultString += `${key}: ${value}\n`;
            }
        });
    } else if (typeof searchParams === 'object') {
        resultString = JSON.stringify(searchParams);
    } else {
        resultString = "Not an object or URLSearchParams"
    }
    return resultString;
}

//Only reason it doesn't work is because we currently use URLSearchParams to encode POST data. URLSearch params does not support objects.
function formatObject(object, indent) {
    let result = '';
    for (const key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
            const value = object[key];
            if (typeof value === "object") {
                result += `\n` + "  ".repeat(indent) + `${key}: `;
                result += formatObject(value, indent + 1);
            } else {
                result += `\n` + "  ".repeat(indent) + `${key}: ${value}`;
            }
        }
    }
    return result;
}

//TODO remove this code only here to update the file on git
//TODO remove this too
//TODO remove this file again