/*
Component: Menu
Purpose: 
It is the main menu and contains the redirects to all pages.
Main functionality:
  -Redirect to any page in the web.
Secondary functionalities:
  -Login and logout
  -Switch theme
  
Useful notes:
Css classes for managing opening a closing of dropdown menus work in the exact opposite way they should.
I have absolutely no clue about why this happens but since it works by reversing the booleans i wont be expending more time there.
  
Decission documentation:

*/

<template>
  <div>
    <div id="upper-menu-container-mobile">
      <div id="upper-menu-icon-container" @click="openMenu">
        <span id="upper-menu-icon" class="nav-link-icon-mobile material-icons-outlined md-24">menu</span>
      </div>
    </div>
    <div id="upper-menu-container-layout-changer-mobile">
    </div>

    <Transition name="fader">
      <!-- Background grey when menu opens -->
      <div id="menu-hider-mobile" @click.self="closeMenu" v-on:touchstart="closeMenu" v-if="this.hover_class == 'menu-open'">
      </div>
    </Transition>

    <Transition name="slider">
      <div
      id="menu-container-mobile"
      v-if="this.hover_class == 'menu-open'"
      >
        <!-- Envelopes nav links with fixed width so changes in root width dont destroy layout -->
        <div id="menu-container-frame-mobile">

          <!-- Profile/Login container -->
          <div id="profile-container-mobile">

            <!-- Profile section -->
            <div v-if="_g_IsUserLogged">

              <div class="profile-selector-container" :class="getCorrectDropdownCss(!this.user_selector.dropdown_active, this.user_selector.options)" @click="()=>this.user_selector.dropdown_active = !this.user_selector.dropdown_active">
                <div class="profile-pic-container-mobile">
                  <span id="profile-pic-center-helper"></span>
                  <span class="profile-pic nav-link-icon material-icons-outlined md-24">account_circle</span>
                </div>
                <div class="profile-text-container-mobile">
                    <span class="profile-title-text typo-headline-6">Mi cuenta</span>
                    <span class="profile-title-text typo-body-2">{{ username }}</span>
                </div>
                <div class="nav-link-dropdown-icon-container-mobile">
                  <span class="nav-link-icon-mobile material-icons-outlined md-24">arrow_drop_down</span>
                </div>
              </div>

              <!-- Profile options -->
              <div class="profile-options-container" :class="this.user_selector.dropdown_active ? 'dropdown-options-closed' : ''">
                <div class="profile-options-container-frame">

                  <router-link tag="div" :to="{ name: option.url_name }" v-for="(option, subindex) in this.user_selector.options" class="nav-dropdown-option" :class="getOptionSelectedClassIfSelected(option.url_name)" :key="subindex">
                    <div class="nav-link-icon-container-mobile">
                      <span class="nav-link-text-mobile">{{option.acronym}}</span>
                    </div>
                    <div class="nav-link-text-container-mobile">
                      <span class="nav-link-text-mobile typo-subtitle-2">{{option.name}}</span>
                    </div>
                  </router-link>

                  <div id="log-out-button-container" @click="this.logOut">
                    <div class="nav-link-icon-container-mobile">
                        <span class="nav-link-text-mobile log-out-text">CS</span>
                    </div>
                    <div class="nav-link-text-container-mobile">
                      <span class="nav-link-text-mobile log-out-text typo-subtitle-2">Cerrar sesión</span>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <!-- Login section -->
            <router-link v-else id="login-selector-mobile" class="nav-selector-container-mobile" tag="div" :to="{ name: 'Login' }">
              <div class="nav-link-icon-container-mobile">
                <span class="nav-link-icon material-icons-outlined md-24">login</span>
              </div>
              <div class="nav-link-text-container-mobile">
                <span class="nav-link-text-mobile">Iniciar sesión</span>
              </div>
            </router-link>

          </div>

          <!-- Nav links container-->
          <div id="link-container-mobile">

            <!-- Selectors section -->
            <template v-for="(selector, index) in main_selectors">

              <!-- Special selectors section -->
              <template v-if="typeof selector.options[0] == 'string'">
                <a v-if="is_admin && selector.options[0] == 'admin'" href="/admin/structure/menu/manage/main-menu" target="_blank" :key="index">
                  <div class="nav-selector-container">
                    <div class="nav-link-icon-container">
                      <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
                    </div>
                    <div class="nav-link-text-container">
                      <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
                    </div>
                  </div>
                </a>
              </template>

              <!-- Registro Eurekers -->
              <template v-if="typeof selector.options[0] == 'string'">
                <a v-if="is_basico_asiste_avanzado && selector.options[0] == 'basico_asiste_avanzado'" href="/registro/eureker" target="_blank" :key="index">
                  <div class="nav-selector-container  important-highlight">
                    <div class="nav-link-icon-container">
                      <span class="nav-link-icon material-icons-outlined md-24">{{selector.icon}}</span>
                    </div>
                    <div class="nav-link-text-container">
                      <span class="nav-link-text typo-subtitle-2">{{selector.name}}</span>
                    </div>
                  </div>
                </a>
              </template>

              <!-- Standalone selectors section -->
              <router-link v-else-if="isEmpty(selector.options)" tag="div" :to="{ name: selector.url_name }" class="nav-selector-container-mobile" :class="getSelectedClassIfSelected(selector.url_name)" :key="index">
                <div class="nav-link-icon-container-mobile">
                  <span class="nav-link-icon-mobile material-icons-outlined md-24">{{selector.icon}}</span>
                </div>
                <div class="nav-link-text-container-mobile">
                  <span class="nav-link-text-mobile typo-subtitle-2">{{selector.name}}</span>
                </div>
              </router-link>

              <!-- Dropdown selectors section -->
              <div v-else :key="index">

                <!-- Dropdown main selector -->
                <div class="nav-selector-container-mobile" :class="getCorrectDropdownCss(!selector.dropdown_active, selector.options)" @click="()=>selector.dropdown_active = !selector.dropdown_active">
                  <div class="nav-link-icon-container-mobile">
                    <span class="nav-link-icon-mobile material-icons-outlined md-24">{{selector.icon}}</span>
                  </div>
                  <div class="nav-link-text-container-mobile">
                    <span class="nav-link-text-mobile typo-subtitle-2">{{selector.name}}</span>
                  </div>
                  <div class="nav-link-dropdown-icon-container-mobile">
                    <span class="nav-link-icon-mobile material-icons-outlined md-24">arrow_drop_down</span>
                  </div>
                </div>

                <!-- Dropdown options -->
                <div class="nav-dropdown-options-container" :class="selector.dropdown_active ? 'dropdown-options-closed' : ''">
                  <div class="nav-dropdown-options-container-frame">

                    <router-link tag="div" :to="{ name: option.url_name }" v-for="(option, subindex) in selector.options" class="nav-dropdown-option" :class="getOptionSelectedClassIfSelected(option.url_name)" :key="subindex">
                      <div class="nav-link-icon-container-mobile">
                        <span class="nav-link-text-mobile">{{option.acronym}}</span>
                      </div>
                      <div class="nav-link-text-container-mobile">
                        <span class="nav-link-text-mobile typo-caption">{{option.name}}</span>
                      </div>
                    </router-link>

                  </div>
                </div>

              </div>
            </template>
            
          </div>

          <!-- Bottom pinned nav links container -->
          <div id="bottom-pinned-container-mobile">

            <!-- Notifications -->
            <div id="notification-selector" class="nav-selector-container" @click="_handlerViewNotificationsContainer">
              <div class="nav-link-icon-container">
                <div style="display: flex; flex-direction: row; margin-left: 22px;">
                  <span class="nav-link-icon material-icons-outlined md-24">notifications</span>
                  <div class="circle" v-if="contador_notificaciones != 0">
                  </div>
                </div>
              </div>
              <div class="nav-link-text-container">
                <span class="nav-link-text">Notificaciones</span>
              </div>
            </div>

            <!-- Theme switcher -->
            <div v-if="router_in_stockchart && _g_User.is_platinum_user" id="theme-selector" class="nav-selector-container-mobile" @click="changeInterfacePlatinum">
              <div class="nav-link-icon-container-mobile">
                <span class="nav-link-icon-mobile material-icons-outlined md-24">contrast</span>
              </div>
              <div class="nav-link-text-container-mobile">
                <span class="nav-link-text-mobile">Cambiar tema de color</span>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from "@/services/auth";

import mainMenu from "@/components/menu/configs/main_menu.json";
import userMenu from "@/components/menu/configs/user_menu.json";
import APICaller from "@/components/codeComponents/APICaller.js";

import { mapGetters } from "vuex";

export default {
  mixins: [APICaller],
  data() {
    return {
      main_selectors: mainMenu,
      user_selector: userMenu,
      current_route: "",
      logged_in: false,

      hover_class: "menu-close",
      last_hide_bar: -999,
      is_touching: false,
      username: '',
      is_admin: false,
      uid: 0,
      is_basic_user: "",
      is_basico_asiste_avanzado: false,
      router_in_stockchart: false,
      contador_notificaciones: 0,
      is_avanzado_off: false,
      is_basico_off: false,
    };
  },
  watch: {
    '$route.name': {
      handler: function() {
        this.current_route = this.$route.name;
        this.closeMenu();
      }
    }
  },
  mounted() {
    this.initializeMenuBarHiding();
    const current_uri = location.pathname;
    const uri_elems = current_uri.split("/");
    if(uri_elems.length > 2) {
      if(uri_elems[2] === 'graficador-dinamico') {
        this.router_in_stockchart = true;
      } 
    }
  },
  created(){
    var waiting_for_user = setInterval(()=>{
      if(this._g_User.uid != undefined){
        this.initUserInfo();
        this._getUsername();
        clearInterval(waiting_for_user);
      }
    },200)
    this.current_route = this.$route.name;
  },
  computed: {
    ...mapGetters(['_g_ThemeName']),
    ...mapGetters(['_g_IsUserLogged', "_g_User"]),
    ...mapGetters(['_g_UserId']),
  },
  methods: {

    //Ini methods

    initializeMenuBarHiding() {
      let prevScrollpos = window.pageYOffset;
      let that = this;
      let scrollMethod = function() {
        let currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
          document.getElementById("upper-menu-container-mobile").style.top = "0";
        } else if (that.is_touching) {
          document.getElementById("upper-menu-container-mobile").style.top = "-64px";
        }
        prevScrollpos = currentScrollPos;
      }

      let touchStartMethod = function() {
        that.is_touching = true;
      }

      let touchEndMethod = function() {
        that.is_touching = false;
      }

      window.addEventListener("scroll", scrollMethod);
      window.addEventListener("touchstart", touchStartMethod);
      window.addEventListener("touchend", touchEndMethod);
    },

    initUserInfo: function(){
      this.is_basico_off = this._g_User.is_basico_off;
      this.is_avanzado_off = this._g_User.is_avanzado_off;
      this.is_basico_asiste_avanzado = this._g_User.is_basico_asiste_avanzado;
      this.uid = this._g_User.uid;
      this.is_admin = this._g_User.is_admin_user;
      this.is_basic_user = this._g_User.is_basic_user;
      this._getAllUserNotifications()
    },

    //Theming

    switchTheme() {
      if(localStorage.getItem('dark_theme') != undefined) {
        localStorage.setItem('dark_theme', !this._g_ThemeName);
        this.changeInterfacePlatinum()
      }
    },

    changeInterfacePlatinum() {

      let success = () => {
        location.reload();
      };

      let url = '/usuarios/change-gd-interface-color';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('025', 'Error cambiando el tema de color.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    //Log out method

    logOut() {

      let success = () => {
        localStorage.removeItem('user_access_token');
        location.reload();
      };


      let url = '/api/v1/users/user/log-out';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('026', 'Error cerrando sesión.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    //Menu open/close functionality

    closeMenu() {
      this.hover_class = "menu-close";
      document.getElementsByTagName("html")[0].classList.remove("noscroll");
    },

    openMenu() {
      this.hover_class = "menu-open";
      document.getElementsByTagName("html")[0].classList.add("noscroll");
    },

    //HTML vue methods

    //Return selected class if selector equals current route
    getSelectedClassIfSelected(selector_url) {
      return this.current_route == selector_url ? 'selected' : '';
    },
    
    //Returns selected class if parameter matches with current router url
    getOptionSelectedClassIfSelected(selector_url) {
      return this.current_route == selector_url ? 'option-selected' : '';
    },

    //Returns whether an array is empty
    isEmpty(list) {
      return list.length === 0;
    },

    //Returns true if any option in the array parameter (options) has a url matching with current router url
    isChildSelected(options) {
      let res = false;
      for (let i = 0; i < options.length; i++) {
        res = options[i].url_name == this.current_route;
        if(res) {break;}
      }
      return res;
    },

    //Returns needed dropdown styles
    getCorrectDropdownCss(dropdown_active, options) {
      let final_style = "";
      let isChildSelected = this.isChildSelected(options);
      if(dropdown_active && isChildSelected) {
        final_style = 'selected-dropdown child-selected';
      }
      else if(dropdown_active) {
        final_style = 'selected-dropdown';
      }
      else if(isChildSelected) {
        final_style = 'child-selected';
      }

      return final_style;
    },

    _getUsername(){
      axios.get(process.env.VUE_APP_API + '/api/v1/users/user/name', { headers: authHeader() })
      .then(
        response => {
          this.username = response.data.name
        },
        error => {
          this.$vueOnToast.pop('error',"Error al obtener el nombre de usuario: " + error);
        }
      )
    },

    _handlerViewNotificationsContainer: function(){
      if(this.notifications_opened){
        let success = response => {
          this.notifications_opened = false
          this.$emit("close_notification_container")
          this._getAllUserNotifications()
        }

        let url = "/api/v1/eurekers-notifications/read-notifications/" + this._g_UserId;

        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler('316', 'Error leyendo las notificaciones.');

        this._getAPICall(url, successHandler, failureHandler);
      }else{
        this.notifications_opened = true
        this.$emit("open_notification_container")
      }
    },

    _getAllUserNotifications: function(){
      let success = response => {
        this.contador_notificaciones = 0
        let notifications = this._checkTimestamps(response.data);
        notifications.forEach(element=>{
          if(element.status == '0'){
            this.contador_notificaciones++
          }
        })
      }

      let url = "/api/v1/eurekers-notifications/" + this._g_UserId;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('317', 'Error recuperando las notificaciones.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _checkTimestamps: function(arr) {
      var notifications = [];
      var today = new Date().getTime(); // get current timestamp
      for(var i = 0; i < arr.length; i++) {
        if(arr[i].timestamp > today) {
          notifications.push(arr[i]);
        }
      }
      return notifications;
    }, 
  },
}
</script>

<style lang="scss">
//modifiable attributes
$selector_height: 48px;
$login_selector_focus_padding: 15px;
$login_selector_not_focus_padding: 7.5px;
$login_selector_focus_font_size: 18px;
$login_selector_focus_icon_size: 28px;
$closed_width: 64px; //Deberian ser 72 pero si no igual no esta para la master porque descuadra todo
$width: 75%;
$border_color: #868993;
$profile_pic_size: 40px;
$profile_padding: 15px;

:root {
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.noscroll {
  overflow: hidden;
}

#menu-hider-mobile {
  background-color: rgba(0,0,0,0.5);
  z-index: 99999999999997;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
}

#menu-container-mobile {
  width: $width;
  background-color: var(--color-primary-primary);
  color: var(--color-typography-on-primary);
  font-weight: bold;
  position: fixed;
  z-index: 99999999999999;
  top: 0px;
  cursor: pointer;
  height: 100% !important;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

#menu-container-frame-mobile {
  z-index: 99999999999998;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

#login-selector-mobile {
  padding: $login_selector_not_focus_padding 0;
  transition: padding, 0.2s;
  transition: font-size, 0.2s;
}

#login-selector-mobile:hover {
  padding: $login_selector_focus_padding 0px;
  font-size: $login_selector_focus_font_size;
}

#login-selector-mobile:hover .md-24 {
  transition: font-size, 0.2s;
}

#login-selector-mobile:hover .md-24 {
  font-size: $login_selector_focus_icon_size !important;
}

#profile-container-mobile {
  order: 1;
  border-bottom: 1px solid $border_color;
}

#link-container-mobile {
  order: 2;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

#bottom-pinned-container-mobile {
  order: 3;
  margin-top: auto;
  border-top: 1px solid $border_color;
}

.nav-selector-container-mobile {
  height: $selector_height;
}

.nav-link-icon-container-mobile, .nav-link-dropdown-icon-container-mobile, .profile-pic-container-mobile {
  width: $closed_width;
  text-align: center;
  display: inline-block;
  vertical-align: top;
}

.nav-link-icon-mobile {
  line-height: $selector_height !important;
  text-align: center;
}

.nav-link-text-container-mobile, .profile-text-container-mobile {
  display: inline-block;
  vertical-align: top;
}

.nav-link-text-mobile {
  line-height: $selector_height !important;
  text-align: center;
}

.nav-link-icon-container-mobile .material-icons-outlined, .nav-link-dropdown-icon-container-mobile .material-icons-outlined {
  margin: 0px !important;
}

.selected, .selected:hover {
  background-color: var(--color-accent-primary);
}

.selected-dropdown .nav-link-dropdown-icon-container-mobile {
  transform: rotate(-180deg);
}

.nav-link-dropdown-icon-container-mobile {
  transition: all .25s ease-out;
  float: right;
}

.nav-dropdown-option {
  background-color: var(--color-primary-900);
  transition: background-color 0.2s;
}

.option-selected {
  background-color: var(--color-accent-primary);
}

.nav-dropdown-options-container, .profile-options-container {
  overflow: hidden;
  overflow-y: auto;
  transition: opacity 0.5s;
}

.dropdown-options-closed {
  height: 0px;
  opacity: 0%;
}

.profile-selector-container {
  padding: $profile_padding 0;
  height: $selector_height;
}

.profile-title-text {
  display: block;
}

.profile-pic-container-mobile {
  height: 100%;
}

.important-highlight {
  background-color: orange;
}

#profile-pic-center-helper {
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.log-out-text {
  color: var(--color-other-error);
}

#log-out-button-container {
  transition: background-color, 0.2s;
}

#log-out-button-container:active {
  background-color: var(--color-accent-primary);
}

#log-out-button-container:active .log-out-text {
  color: white !important;
}

.profile-pic {
  display: inline-block;
  vertical-align: middle;
  height: $profile_pic_size;
}

//Slider transition

.slider-enter-active,
.slider-leave-active {
  transition: all .15s !important;
}

.slider-enter,
.slider-leave-to {
  transform: translateX(-100vw) !important;
}

//Fader transition

.fader-enter-active,
.fader-leave-active {
  transition: opacity .15s !important;
}

.fader-enter,
.fader-leave-to {
  opacity: 0 !important;
}

#upper-menu-container-mobile {
  background-color: var(--color-primary-primary);
  height: 64px;
  top: 0;
  width: 100%;
  transition: top 0.3s;
  position: fixed;
  z-index: 3;
}

#upper-menu-container-layout-changer-mobile {
  background-color: transparent;
  height: 48px;
  width: 100%;
}

#upper-menu-icon-container {
  height: 64px;
  width: 64px;
  vertical-align: middle;
  text-align: center;
}

#upper-menu-icon {
  line-height: 64px !important;
  color: white;
  font-size: 30px;
}

</style>