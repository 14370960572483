<template>
  <div>
    <p>Sin uso este componente</p>
  </div>
</template>


<script>

export default {
  name: "States",
  data: () => ({}),
  computed: {
  }
};
</script>


<style lang="scss" scoped>
  p {color:blue}
</style>
