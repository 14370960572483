import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import getters from './getters'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  // main state test
  state: {
    user: {},
    user_features: {},
    user_id: 0,
    active_overlays: 0,
    has_to_refresh_token: false,
    selected_ticker: {},
    workspace_name: '',
    array_filters_stocks: [{name:"<35", value:true},{name:"35-75", value:false}, {name:"75-150", value:true}, {name:"150-400", value: true}, {name:">400", value:false}, {name:"mensual", value:true}, {name:"IPO", value:false}, {name:"bajista", value:false}, {name:"alcista", value:true}, {name:"stock", value: true}, {name:"ETF", value:false}, {name:"PER", value:true}, {name:"RR", value:false},{name:"RRA", value: true}, {name:"LT", value: true}, {name:"LTA", value:false},{name:"mont_inferior", value: "800"}, {name:"mont_superior", value:"4000"}, {name:"dist_inferior", value: "0%"}, {name:"dist_superior", value:"25% o más"}, {name:"edad_inferior", value:"< 1"}, {name:"edad_superior", value:"> 25"}, {name:"div_0", value:true}, {name:"div1-2", value:false}, {name:"div3-mas", value: true}, {name:"rent_<35", value:"< 35"}, {name:"rent_>400", value:"> 400"}, {name:"wave_all", value:"All"}, {name:"wave_>25", value:"> 25"}, {name:"CFDs", value: "No (Muestra todos los valores)"}, {name:"divisas", value: "Todas las divisas"}, {name:"pais", value: "Todos los paises"}, {name:"sector|industria", value: "Todos los sectores | Todas las industrias"}],
    array_filters_stocks_default: [{name:"<35", value:true},{name:"35-75", value:false}, {name:"75-150", value:true}, {name:"150-400", value: true}, {name:">400", value:false}, {name:"mensual", value:true}, {name:"IPO", value:false}, {name:"bajista", value:false}, {name:"alcista", value:true}, {name:"stock", value: true}, {name:"ETF", value:false}, {name:"PER", value:true}, {name:"RR", value:false},{name:"RRA", value: true}, {name:"LT", value: true}, {name:"LTA", value:false},{name:"mont_inferior", value: "800"}, {name:"mont_superior", value:"4000"}, {name:"dist_inferior", value: "0%"}, {name:"dist_superior", value:"25% o más"}, {name:"edad_inferior", value:"< 1"}, {name:"edad_superior", value:"> 25"}, {name:"div_0", value:true}, {name:"div1-2", value:false}, {name:"div3-mas", value: true}, {name:"rent_<35", value:"< 35"}, {name:"rent_>400", value:"> 400"}, {name:"wave_all", value:"All"}, {name:"wave_>25", value:"> 25"}, {name:"CFDs", value: "No (Muestra todos los valores)"}, {name:"divisas", value: "Todas las divisas"}, {name:"pais", value: "Todos los paises"}, {name:"sector|industria", value: "Todos los sectores | Todas las industrias"}],
    array_filters_criptos: [{name:"bajista", value:false}, {name:"alcista", value:true}, {name:"PER", value:true}, {name:"RR", value:false},{name:"RRA", value: true}, {name:"LT", value: true}, {name:"LTA", value:false}, {name:"dist_inferior", value: "0%"}, {name:"dist_superior", value:"25% o más"}, {name:"edad_inferior", value:"< 1"}, {name:"edad_superior", value:"> 25"}, {name:"rent_<35", value:"< 35"}, {name:"rent_>400", value:"> 400"}, {name:"wave_all", value:"All"}, {name:"wave_>25", value:"> 25"}],
    array_filters_criptos_default:[{name:"bajista", value:false}, {name:"alcista", value:true}, {name:"PER", value:true}, {name:"RR", value:false},{name:"RRA", value: true}, {name:"LT", value: true}, {name:"LTA", value:false}, {name:"dist_inferior", value: "0%"}, {name:"dist_superior", value:"25% o más"}, {name:"edad_inferior", value:"< 1"}, {name:"edad_superior", value:"> 25"}, {name:"rent_<35", value:"< 35"}, {name:"rent_>400", value:"> 400"}, {name:"wave_all", value:"All"}, {name:"wave_>25", value:"> 25"}],
    array_filters: [],
    portfolio_list: [],
    portfolio_id_selected: undefined,
    sticky_menu: false,
    dark_theme: undefined,
    show_stockchart_list: true,
    show_stock_finder_filters: false,
    show_stockchartmenu: true,
    is_mobile_device: false,
    handler_toggle_views: {
        broker_simulator_take_profit: false,
        broker_simulator_stoploss: false,
        broker_simulator_oco: false,
        stop_loss_is_ipo:false,
    },
    refresh_table: false,
    is_tablet_device: false,
    finder: "",
    recharge: false,
    sidebarRight: false,
    stock: "stock",
    menuSelectionOut: false,
    indexMenu: false,
    changes_in_portfolios: false,
    stocks: [],
    is_loading_stocks: false,
    chart_view: false,
    stocks_view: false,
    toggleChartStocks: "chart",
    open_filters: false,
    open_favorites: false,
    open_searches: false,
    open_info: false,
    open_fundamental: false,
    open_description: false,
    open_order: false,
    open_comments: false,
    open_error: false,
    open_simulator: false,
    open_brain: false,
    open_notas: false,
    open_alarms: false,
    active_right_component: "",
    crypto_enable: false,
    user_info: [],
    order_by: [],
    workspace_created: false,
    tickers_finder: false,
  },
    getters,
    mutations,
    actions,
    modules: {}
})